<template>
  <div class="pages">
    <div class="back-up">
      <a href="javascript:;" @click="$router.back()">返回上一级</a>
    </div>
    <div class="message-title">
      <div class="title">
        <span class="line"></span>
        <span>消息管理</span>
      </div>
    </div>
    <div class="message-info">
      <div class="date">
        <span class="title">时间</span>
        <span class="content">{{pageData?.create_time}}</span>
      </div>
      <div class="from">
        <span class="title">发件人</span>
        <span class="content">管理员</span>
      </div>
      <div class="content">{{pageData?.content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageInfo',
  data() {
    return {
      pageData: {},
    }
  },
  mounted() {
    this.pageData = JSON.parse(this.$route?.params?.messageData||{})
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "MessageInfo.scss";
</style>
